import React from 'react';
import { createRoot } from 'react-dom/client';
import ToastContainer from './ToastContainer';

function renderToastContainer() {
  const appRootNode = document.createElement('div');
  appRootNode.id = 'toasts-container';
  document.body.appendChild(appRootNode);

  const root = createRoot(appRootNode);
  root.render(<ToastContainer />);
}

renderToastContainer();
