import React, { useEffect } from 'react';
import { Toaster } from 'sonner';
import { showDjangoMessageAsToast } from './toast';
import {
  htmxErrorListener,
  showHtmxDjangoMessagesListener,
} from './eventListeners';
import type { DjangoMessage } from './types';

/**
 * Find all normal (non-htmx) Django messages and show them as toasts.
 */
function findAndShowDjangoMessages() {
  const messageElements = document.querySelectorAll<HTMLDivElement>(
    'div[data-django-message]'
  );

  messageElements.forEach((messageElement) => {
    const { djangoMessage, djangoMessageLevel, djangoMessageTags } =
      messageElement.dataset;

    showDjangoMessageAsToast({
      message: djangoMessage as DjangoMessage['message'],
      level: djangoMessageLevel as DjangoMessage['level'],
      tags: djangoMessageTags as DjangoMessage['tags'],
    });
  });
}

const ToastContainer = () => {
  useEffect(() => {
    findAndShowDjangoMessages();

    document.body.addEventListener(
      'showHtmxDjangoMessages',
      showHtmxDjangoMessagesListener
    );
    document.body.addEventListener('htmx:beforeOnLoad', htmxErrorListener);

    return () => {
      document.body.removeEventListener(
        'showHtmxDjangoMessages',
        showHtmxDjangoMessagesListener
      );
      document.body.removeEventListener('htmx:beforeOnLoad', htmxErrorListener);
    };
  }, []);

  return <Toaster visibleToasts={5} style={{ paddingLeft: 0 }} expand />;
};

export default ToastContainer;
