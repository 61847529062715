import { CodeBracketIcon } from '@heroicons/react/24/outline';
import React from 'react';
import showToast, { showDjangoMessageAsToast } from './toast';
import type { DjangoMessage } from './types';

/**
 * Decode the base64-encoded messages generated in HtmxDjangoMessagesMiddleware,
 * and show them as toasts.
 */
export const showHtmxDjangoMessagesListener = ((
  event: CustomEvent<{ encodedMessages: string }>
) => {
  const decodedMessages = JSON.parse(
    window.atob(event.detail.encodedMessages)
  ) as Array<DjangoMessage>;
  return decodedMessages.forEach((message) =>
    showDjangoMessageAsToast(message)
  );
}) as EventListener;

/**
 * Show an error toast when an htmx request fails.
 */
export const htmxErrorListener = ((
  event: CustomEvent<{ xhr: XMLHttpRequest }>
) => {
  const { xhr: response } = event.detail;

  if (response.status < 400) {
    return;
  }

  let errorContent: string | React.ReactNode;

  const isLocalResponse =
    !!response.responseURL.match(/^http:\/\/local\-\w+\.otovo\.\w+:\d+/) ||
    !!response.responseURL.match(/^http:\/\/localhost:\d+/);
  const isDebug = isLocalResponse && response.response.includes('DEBUG = True');

  switch (response.status) {
    case 400:
      errorContent = 'Bad request. Please check the input and try again.';
      if (response.responseText) {
        errorContent = (
          <>
            {errorContent} Message:
            <br />
            {response.responseText}
          </>
        );
      }
      break;

    case 403:
      errorContent =
        'You are not authorized to perform this action. Contact us if you think this is an error.';
      break;

    case 404:
      errorContent = 'The requested URL could not be found.';
      break;

    case 500:
      const sentryEventId = response.getResponseHeader('Sentry-Event-Id');
      const isSuperuser = response.getResponseHeader('Is-Superuser') == 'True';
      errorContent = (
        <span>
          Something went wrong while processing the request.{' '}
          {renderSentryEventId(sentryEventId, isSuperuser)}
        </span>
      );
      break;

    default:
      errorContent = (
        <span>
          An unexpected error occurred. Error code:
          <br />
          <code className="_text-grey-80">
            HTTP {response.status} {response.statusText}
          </code>
        </span>
      );
  }
  showToast({
    type: 'error',
    content: (
      <>
        {errorContent}
        {isDebug && (
          <button
            className="_flex _items-center _gap-x-1 _text-blue-40 hover:_underline"
            onClick={() => {
              // Replace the content of the current page with the Django debug output
              document.open();
              document.write(response.response);
              document.close();
            }}
          >
            Show debug screen
            <CodeBracketIcon className="_w-4 _h-4 _stroke-2" />
          </button>
        )}
      </>
    ),
  });
}) as EventListener;

const renderSentryEventId = (
  sentryEventId: string | null,
  isSuperuser: boolean
) => {
  if (!sentryEventId) {
    return null;
  }

  if (isSuperuser) {
    return (
      <>
        Sentry event ID:{' '}
        <a
          href={`https://sentry.io/organizations/otovo/issues/?query=${sentryEventId}`}
          target="_blank"
        >
          {sentryEventId}
        </a>
      </>
    );
  }

  return (
    <>
      Please copy and paste this ID if you ask for help:
      <br />
      <code className="_text-grey-80">{sentryEventId}</code>
    </>
  );
};
